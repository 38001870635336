
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

import { useToast } from "vue-toastification";
import { ref } from "yup";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import { Modal } from "bootstrap";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import { hideModal } from "@/core/helpers/dom";
import UploadCardStatus from "@/components/1crm/1sekolah/product/card-design/upload-card-status.vue";

moment.locale("id");
export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
    UploadCardStatus,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      tableData: [],
      listData: [],
      listCompany: [],
      listSekolah: [],
      listCategory: [],
      listStatus: [],
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      aksesSimpanCardDesain: false,
      uploadFotoMultiple: false,
      verifikasiMultipleCondition: false,
      historyUpload: false,
      download: false,
      tambahUser: false,
      verifikasiSingle: false,
      uploadFotoSingle: false,
      editDataSingle: false,
      hapusDataSingle: false,

      showDropdownBtn: false,
      showDropdownBtnHistory: false,
      showDropdownBtnHDownload: false,

      resultImportExcel: false,
      successImport: false,

      listDenganFoto: [
        {
          _id: "false",
          name: "Belum Ada Foto",
        },
        {
          _id: "true",
          name: "Sudah Ada Foto",
        },
      ],
      listValidasi: [
        {
          _id: "true",
          name: "Sudah Verifikasi",
        },
        {
          _id: "false",
          name: "Belum Verifikasi",
        },
      ],
      listPrintStatus: [
        {
          _id: "true",
          name: "Sudah Di Cetak",
        },
        {
          _id: "false",
          name: "Belum Di Cetak",
        },
      ],
      listItems: [
        {
          _id: 1,
          name: "Nama Siswa",
          nis: "000000000000",
          nisn: "000000000000000",
          placeOfBirth: "Salatiga",
          dateOfBirth: "01 Januari 1999",
          gender: "-",
          grade: "-",
          gradeDetail: "-",
          address: "-",
          tags: "['-' , '-']",
          note: "-",
          isPrint: "Belum Cetak",
          isInternalValid: "Belum Verifikasi",
          isSchoolValid: "Belum Verifikasi",
        },
      ],

      targetData: {
        _id: 1,
        name: "Nama Siswa",
        nis: "000000000000",
        nisn: "000000000000000",
        placeOfBirth: "Salatiga",
        dateOfBirth: "01 Januari 1999",
        gender: "-",
        grade: "-",
        gradeDetail: "-",
        address: "-",
        tags: "['-' , '-']",
        note: "-",
        isPrint: false,
        isInternalValid: false,
        isSchoolValid: false,
      },

      detail: {
        createTime: "",
        creatorId: "",
        updateTime: "",
        editorId: "",
        editorName: "",
        creatorName: "",
        executorName: "",
        deleteBy: "",
        deleteTime: "",
        cardId: "",
        companyId: "",
        userId: "",
        name: "",
        isDelete: false,
        active: false,
        accountNumber: "",
        accountId: "",
        callerName: "",
        callerId: "",
        tags: "",
        nfcId: "",
      },

      targetDataImage: {
        filedepan: "",
        filebelakang: "",
        fotopersonal: "",
      },

      targetDataUploadExcel: {
        // idSchool: "",
        fileexcel: "",
      },
      namaSekolah: "",
      errorUploadExcel: {
        sekolah: "",
        file: "",
      },

      schoolIdSelected: "",

      schoolNameImport: "",

      tableDataHistory: [],

      targetDataUploadFotoMulti: {
        // idSchool: "",
        listFotoMulltiple: [],
      },

      summary: {
        total: 0,
        photoUrl: 0,
        nothingphotoUrl: 0,
        isSchoolValid: 0,
        isInternalValid: 0,
      },

      showResultUploadMultiple: false,
      resultTotalMultipleUpload: 0,
      resultSuccessMultipleUpload: 0,
      resultFailedMultipleUpload: 0,

      textColorVerifikasiAdmin: "red",
      textColorVerifikasiSekolah: "red",
      textColorCetak: "red",

      schoolIdUpdate: "",
      cardIdUpdate: "",

      imageFotoVertikal: "/media/no-image/No_Image_Vertikal_TKI.png",
      imageFotohorizontal: "/media/no-image/No_Image_Horizontal_TKI.png",
      imageFotoHuman: "/media/no-image/No_Image_Photo_TKI.png",
      imageFotoPersonal: "/media/no-image/No_Image_Photo_TKI.png",
      imageFoto: "/media/no-image/No_Image_Photo_TKI.png",
      urlimage1: "/media/no_image.JPG",
      urlimagedepanshow: "/media/no-image/No_Image_Horizontal_TKI.png",
      urlimagebelakangshow: "/media/no-image/No_Image_Horizontal_TKI.png",

      fileInputKey3: 0,
      fileInputKeyMultiple: 0,

      modalShow: false,

      nama: "",
      role: "",
      tag: "",
      nis: "",
      isPhoto: "",
      isSchool: "",
      isPrint: "",
      filterPhoto: false,
      filterValidasi: false,
      filterPrint: false,
      // categoryId: "",
      schoolId: "",

      idtext: "",
      // elements:"",
    };
  },

  beforeMount() {
    // store.dispatch(Actions.VERIFY_AUTH);
    // this.getData(this.paging.size, this.paging.page);
    this.getData(this.paging.size, this.paging.page);
    // var pagingsize = localStorage.getItem("pagingsize")!;
    // var pagingpage = localStorage.getItem("pagingpage")!;
    // this.getListSekolah();
    this.schoolIdSelected = JSON.parse(
      localStorage.getItem("user_account")!
    ).schoolId;

    this.namaSekolah = JSON.parse(localStorage.getItem("user_account")!).name;
    // console.log("id",this.schoolId);
    // if(this.schoolId){
    //   this.schoolIdSelected = this.schoolId
    //   if(pagingsize){
    //     this.paging.size = Number(pagingsize);
    //     localStorage.removeItem("pagingsize");
    //   }
    //   if(pagingpage){
    //     this.paging.page = Number(pagingpage);
    //     localStorage.removeItem("pagingpage");
    //   }
    //   this.getData(this.paging.size, this.paging.page);
    //   console.log('isi');
    // }else{
    //     this.listItems = [];
    // }
    // setCurrentPageBreadcrumbs("Data Produk", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    handleNoEdit() {
      Swal.fire({
        title: "Edit Data KArtu",
        text: `Data kartu sudah di verifikasi, jika ada perubahan data silahkan menghubungi tim kami`,
        type: "info",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonClass: "btn btn-info btn-fill",
        cancelButtonText: "Ok",
        buttonsStyling: false,
      }).then((result) => {});
    },
    getData(size, page) {
      store.dispatch(Actions.VERIFY_AUTH);
      this.isLoading = true;

      let companyId = "";
      let nama = "";
      let nis = "";
      let tag = "";
      let isPhoto = "";
      let isSchool = "";
      let isPrint = "";
      let valcategoryId = "";
      // if (this.categoryId) {
      //   valcategoryId = this.categoryId;
      // }
      // if (this.companyId) {
      //   companyId = this.companyId;
      // }
      if (this.nama) {
        nama = "&name=" + this.nama;
      }
      if (this.nis) {
        nis = "&nis=" + this.nis;
      }
      if (this.tag) {
        tag = "&tag=" + this.tag;
      }
      if (this.isPhoto) {
        isPhoto = "&isPhoto=" + this.filterPhoto;
      }
      if (this.isSchool) {
        isSchool = "&isSchool=" + this.filterValidasi;
      }
      if (this.isPrint) {
        isPrint = "&isPrint=" + this.filterPrint;
      }

      ApiService.getWithoutSlug(
        "crmv2/main_card/card_data/all?page=" +
          page +
          nama +
          nis +
          tag +
          isPhoto +
          isSchool +
          isPrint +
          "&size=" +
          size +
          "&sort=name&dir=1"
      )
        .then(({ data }) => {
          // this.tableData = data.content;
          this.listItems = data.content;
          localStorage.setItem("listData", JSON.stringify(data.content));
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },
    // getDetail(idDetail){
    //   store.dispatch(Actions.VERIFY_AUTH);
    //     ApiService.getWithoutSlug(
    //     "crmv2/main_card/card/" + idDetail
    //   )
    //     .then((res) => {
    //       this.detail = res.data;
    //       this.detail.createTime = this.dateTime(res.data.createTime) ;
    //       this.detail.updateTime = this.dateTime(res.data.updateTime);
    //     //   this.detail.imageshow = res.data.imgUrl[0];

    //       console.log(this.detail);

    //       this.isLoading = false;
    //     })
    //     .catch((e) => {
    //     //   toast.error(e.res.data.detail);

    //       this.isLoading = false;
    //     });
    // },
    // getListSekolah() {
    //   ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
    //     ({ data }) => {
    //       this.listSekolah = data;
    //       localStorage.setItem("listSchool", JSON.stringify(data));
    //     }
    //   );
    // },

    // pilihSekolah(event) {
    //   // console.log(event.target.value);
    //   console.log(event);
    //   // this.schoolId = event;
    //   if (event) {
    //     this.schoolIdSelected = event;
    //   } else {
    //     this.schoolIdSelected = "";
    //     this.listItems = [];
    //   }
    //   // this.getQtyStudent();
    //   localStorage.setItem("schoolIdCardDesign", event);
    //   this.getData(this.paging.size, this.paging.page);
    // },
    pilihFilterFoto(event) {
      if (event) {
        if (event == "false") {
          this.filterPhoto = false;
        } else {
          this.filterPhoto = true;
        }
        this.isPhoto = event;
      } else {
        this.isPhoto = "";
      }
      this.getData(10, 0);
    },
    pilihFilterValidasi(event) {
      if (event) {
        if (event == "false") {
          this.filterValidasi = false;
        } else {
          this.filterValidasi = true;
        }
        this.isSchool = event;
      } else {
        this.isSchool = "";
      }
      this.getData(10, 0);
    },
    filterPrintStatus(event) {
      console.log(event);
      if (event) {
        if (event == "false") {
          this.filterPrint = false;
        } else {
          this.filterPrint = true;
        }
        this.isPrint = event;
      } else {
        this.isPrint = "";
      }
      this.getData(10, 0);
    },
    // clickview(val){
    //     this.getDetail(val.id);
    // },

    myFunction() {
      if (this.showDropdownBtn == true) {
        this.showDropdownBtn = false;
      } else {
        this.showDropdownBtn = true;
      }
      this.showDropdownBtnHistory = false;
      this.showDropdownBtnHDownload = false;
    },

    HistoryFunction() {
      if (this.showDropdownBtnHistory == true) {
        this.showDropdownBtnHistory = false;
      } else {
        this.showDropdownBtnHistory = true;
      }
      this.showDropdownBtn = false;
      this.showDropdownBtnHDownload = false;
    },
    downloadFunction() {
      if (this.showDropdownBtnHDownload == true) {
        this.showDropdownBtnHDownload = false;
      } else {
        this.showDropdownBtnHDownload = true;
      }
      this.showDropdownBtn = false;
      this.showDropdownBtnHistory = false;
    },
    unduhDataSemua() {
      const toast = useToast();
      // toast.error("File Belum Ada");

      this.isLoading = true;
      ApiService.getDownload(`crmv2/main_card/card_data/download`)
        .then((response) => {
          // this.handleBlobResponse(response.data, item, extension);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          console.log(url, " ini sekul");
          const link = document.createElement("a");
          link.href = url;

          // console.log(res.data);
          var extension = "xlsx";
          link.setAttribute(
            "download",
            "Data_Kartu_" + this.namaSekolah + "." + extension
          ); // nama file dan extension sesuaikan dengan file yang di download
          document.body.appendChild(link);
          link.click();
          this.isLoading = false;
          toast.success("Sukses Unduh File");
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          toast.error("Pilih Sekolah terlebih dahulu");
          this.isLoading = false;
        });
    },
    unduhFotoSemua() {
      const toast = useToast();
      // toast.error("File Belum Ada");

      var a = JSON.parse(localStorage.getItem("user_account")!).schoolId;
      this.isLoading = true;
      ApiService.getDownload(`crmv2/main_image/card/archive`)
        .then((response) => {
          // this.handleBlobResponse(response.data, item, extension);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          console.log(url, " ini sekul");
          const link = document.createElement("a");
          link.href = url;

          // console.log(res.data);
          var extension = "zip";
          link.setAttribute(
            "download",
            "image_card_" + this.namaSekolah + "." + extension
          ); // nama file dan extension sesuaikan dengan file yang di download
          document.body.appendChild(link);
          link.click();
          this.isLoading = false;
          toast.success("Sukses Unduh File");
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          toast.error("Pilih Sekolah terlebih dahulu");
          this.isLoading = false;
        });
    },

    handlefileexcel(event) {
      const toast = useToast();
      const val = event.target.files[0].name;
      if (event.target.files[0]) {
        const myArray = val.split(".");
        if (myArray[1] == "xlsx") {
          this.targetDataUploadExcel.fileexcel = event.target.files[0];
        } else {
          toast.error("File Hanya .xlsx");
          this.errorUploadExcel.file = "File Wajib Isi dan File Hanya .xlsx";
        }
      } else {
        toast.error("File Kosong");
      }
    },
    importExcel() {
      this.resultImportExcel = false;
      this.successImport = false;
      const modal = new Modal(document.getElementById("kt_modal_upload_excel"));
      modal.show();
      this.showDropdownBtn = false;
      // this.targetDataUploadExcel.idSchool = this.schoolIdSelected;
    },
    importExcelVerifikasi() {
      const toast = useToast();
      if (this.targetDataUploadExcel.fileexcel === "") {
        if (this.targetDataUploadExcel.fileexcel === "") {
          if (this.errorUploadExcel.file) {
          } else {
            this.errorUploadExcel.file = "File Wajib Diisi";
          }
        }
        toast.error("Form belum lengkap");
      } else {
        this.isLoading = true;
        let formData = new FormData();
        formData.append("file", this.targetDataUploadExcel.fileexcel);

        ApiService.postWithData("crmv2/main_card/card_upload/upload", formData)
          .then((res) => {
            toast.success("Berhasil Upload Dokumen");
            this.targetDataUploadExcel.fileexcel = "";

            // var modal = new Modal(document.getElementById("kt_modal_upload_excel"));
            // modal.hide();

            // var modal2 = new Modal(document.getElementById("kt_modal_history"));
            // modal2.show();
            // this.$router.push("/sekolah/card-design/history-upload");

            // this.schoolNameImport = ""

            this.resultImportExcel = true;
            this.successImport = true;
            this.isLoading = false;
            // items.fileInputKey++;
            // hideModal(uploadModalRef.value);
            // isLoading.value = false;
            // items.disableButton = false;

            // location.reload();
          })
          .catch((e) => {
            this.resultImportExcel = true;
            this.successImport = false;
            this.isLoading = false;
            toast.error(e.response.data.detail);
          });
      }
    },

    goToPageHistory() {
      this.$router.push("/sekolah/card-design/history-upload");
    },

    downloadTemplateExcel() {
      return window.open(
        "https://drive.google.com/uc?export=download&id=1hK1oHqQRzS6K4zNiMHf3MfEuvVL8fc2K",
        "_blank"
      );
      const toast = useToast();
      // toast.error("File Belum Ada");
      this.isLoading = true;
      ApiService.getDownload("crmv2/main_card/util/template/download")
        .then((response) => {
          // this.handleBlobResponse(response.data, item, extension);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          var extension = "xlsx";
          link.setAttribute("download", "Template_Excel" + "." + extension); // nama file dan extension sesuaikan dengan file yang di download
          document.body.appendChild(link);
          link.click();
          this.isLoading = false;

          toast.success("Sukses Unduh File");
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          toast.error("Gagal Unduh File");
          this.isLoading = false;
        });
    },

    History() {
      this.$router.push("/sekolah/card-design/history-upload");
    },
    HistoryFoto() {
      this.$router.push("/sekolah/card-design/history-upload-photo");
    },
    HistoryFotoFromUpload() {
      // hideModal(this.uploadModalMultipleRef.value);
      // this.$refs.uploadModalMultipleRef.hide()
      // this.$refs['uploadModalMultipleRef'].hide();
      // $('#kt_modal_upload_foto_multiple').modal('hide');
      setTimeout(
        () => this.$router.replace("/sekolah/card-design/history-upload-photo"),
        1000
      );
    },
    cancelDesain() {},
    simpanDesain() {},

    uploadPersonal(item) {
      this.targetData = item;
      this.schoolIdUpdate = item.schoolId;
      this.cardIdUpdate = item._id;
      this.imageFotoPersonal = "/media/no-image/No_Image_Photo_TKI.png";
      this.targetDataImage.fotopersonal = "";
      this.fileInputKey3++;
    },
    simpanUploadPersonal() {
      const toast = useToast();
      if (this.targetDataImage.fotopersonal == "") {
        toast.error("File Tidak Boleh Kosong");
      } else {
        this.isLoading = true;

        let formData = new FormData();
        formData.append("file", this.targetDataImage.fotopersonal);

        ApiService.postWithData(
          "crmv2/main_image/card/upload/" + this.cardIdUpdate,
          formData
        )
          .then((res) => {
            // cekSuccess = true;
            toast.success("Berhasil Upload Foto Siswa");

            // location.reload();
            setTimeout(
              () => this.getData(this.paging.size, this.paging.page),
              2000
            );
            // setTimeout(() => this.isLoading = false;, 2000);
            // setTimeout(() => this.basketAddSuccess = false, 2000);
          })
          .catch((e) => {
            toast.error(e.response.data.detail);
            this.getData(this.paging.size, this.paging.page);
            this.isLoading = false;
          });
      }
    },
    editPersonal(item) {
      this.targetData = item;
      this.schoolIdUpdate = item.schoolId;
      this.cardIdUpdate = item._id;
    },
    simpanEditPersonal() {
      this.isLoading = true;
      const toast = useToast();

      ApiService.putWithData(
        "/crmv2/main_card/card_data/" + this.cardIdUpdate,
        this.targetData
      )
        .then((res) => {
          // cekSuccess = true;
          toast.success("Berhasil Edit Data Siswa");
          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        });
    },
    batalEditPersonal() {
      this.getData(this.paging.size, this.paging.page);
    },

    verifikasiPersonal(index, item) {
      Swal.fire({
        title: "Apakah Anda Yakin Verifikasi?",
        text: `Cek kembali semua data sebelum verifikasi`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, Verifikasi !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.verifikasiRow(item);
        }
      });
    },
    verifikasiRow(item) {
      this.targetData = item;
      this.targetData.isSchoolValid = true;
      this.isLoading = true;
      const toast = useToast();

      ApiService.putWithData(
        "/crmv2/main_card/card_data/" + item._id,
        this.targetData
      )
        .then((res) => {
          // cekSuccess = true;
          toast.success("Berhasil Verifikasi Data Siswa");
          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        });
    },

    hapusPersonal(index, item) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: `Data yang telah dihapus tidak dapat dikembalikan !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },
    deleteRow(item) {
      const toast = useToast();
      this.isLoading = true;

      ApiService.delete("/crmv2/main_card/card_data/" + item._id)
        .then((res) => {
          toast.success("Hapus Berhasil");

          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        })

        .catch((error) => {
          toast.error("Gagal Hapus");
          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        });
    },

    verifikasiMultiple() {
      this.showDropdownBtnHistory = false;
      this.showDropdownBtn = false;
      this.isLoading = true;
      ApiService.getWithoutSlug("crmv2/main_card/card_data/summary/total")
        .then((res) => {
          this.summary.total = res.data.total;
          this.summary.photoUrl = res.data.photoUrl;
          this.summary.isSchoolValid = res.data.isSchoolValid;
          this.summary.isInternalValid = res.data.isInternalValid;
          this.summary.nothingphotoUrl = res.data.total - res.data.photoUrl;
          // this.detail = res.data;

          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
      const modal = new Modal(
        document.getElementById("kt_modal_verifikasi_multiple")
      );
      modal.show();
    },
    simpanVerifikasiMultiple() {
      this.isLoading = true;
      const toast = useToast();

      ApiService.PutMethodWithoutData(
        "crmv2/main_card/card_data/validation/school/all?status=true"
      )
        .then((res) => {
          // cekSuccess = true;
          toast.success("Berhasil Verifikasi Semua Data Siswa");
          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        });
    },
    showUploadMultiple() {
      this.showDropdownBtnHistory = false;
      this.showDropdownBtn = false;
      this.showResultUploadMultiple = false;
      const modal = new Modal(
        document.getElementById("kt_modal_upload_foto_multiple")
      );
      modal.show();
      this.fileInputKeyMultiple++;
      this.targetDataUploadFotoMulti.listFotoMulltiple = [];
      document.getElementById("kt_modal_upload_foto_multiple")!.style.display =
        "block";
      // this.showDropdownBtn = false;
      // this.targetDataUploadFotoMulti.idSchool = this.schoolIdSelected;
    },
    simpanFotoMultiple() {
      const toast = useToast();
      if (this.targetDataUploadFotoMulti.listFotoMulltiple.length > 0) {
        this.isLoading = true;

        let formData = new FormData();

        for (
          var i = 0;
          i < this.targetDataUploadFotoMulti.listFotoMulltiple.length;
          i++
        ) {
          let file = this.targetDataUploadFotoMulti.listFotoMulltiple[i];
          formData.append("files", file);
        }

        // let formData = new FormData();
        // formData.append("file", this.targetDataImage.fotopersonal);

        ApiService.postWithData("crmv2/main_image/card/upload", formData)
          .then((res) => {
            // cekSuccess = true;
            toast.info("Proses Upload Data Foto Selesai");

            // location.reload();
            // const modal = new Modal(document.getElementById("kt_modal_upload_foto_multiple"));
            // modal.hide();

            this.showResultUploadMultiple = true;
            this.resultSuccessMultipleUpload = res.data.process.total;
            this.resultFailedMultipleUpload = res.data.failed.total;
            this.resultTotalMultipleUpload =
              res.data.process.total + res.data.failed.total;

            localStorage.setItem("pagingsize", "" + this.paging.size);
            localStorage.setItem("pagingpage", "" + this.paging.page);

            // document.getElementById("kt_modal_upload_foto_multiple")!.style.display = "none";
            // location.reload();
            this.isLoading = false;
            this.getData(this.paging.size, this.paging.page);
            // setTimeout(() => this.getData(this.paging.size, this.paging.page), 2000);
          })
          .catch((e) => {
            const modal = new Modal(
              document.getElementById("kt_modal_upload_foto_multiple")
            );
            modal.show();
            toast.error(e.response.data.detail);
            this.getData(this.paging.size, this.paging.page);
            this.isLoading = false;
          });
      } else {
        toast.error("File Kosong");
      }
    },

    handleFileDesainDepan(event) {
      this.urlimagedepanshow = URL.createObjectURL(event.target.files[0]);
      this.targetDataImage.filedepan = event.target.files[0];
    },
    handleFileDesainBelakang(event) {
      this.urlimagebelakangshow = URL.createObjectURL(event.target.files[0]);
      this.targetDataImage.filebelakang = event.target.files[0];
    },
    handleFotoSiswaPersonal(event) {
      const toast = useToast();
      const val = event.target.files[0].name;
      if (event.target.files[0]) {
        const myArray = val.split(".");
        if (
          myArray[1] == "jpg" ||
          myArray[1] == "jpeg" ||
          myArray[1] == "png"
        ) {
          this.imageFotoPersonal = URL.createObjectURL(event.target.files[0]);
          this.targetDataImage.fotopersonal = event.target.files[0];
          // this.targetDataUploadExcel.fileexcel = event.target.files[0];
        } else {
          toast.error("File Hanya .jpg .jpeg .png");
          this.fileInputKey3++;
          // this.errorUploadExcel.file = "File Wajib Isi dan File Hanya .xlsx";
        }
      } else {
        toast.error("File Kosong");
        this.fileInputKey3++;
      }
    },
    handlefilefotomultiple(event) {
      if (event.target.files[0]) {
        this.targetDataUploadFotoMulti.listFotoMulltiple = event.target.files;
      } else {
        this.fileInputKeyMultiple++;
      }
    },

    handleBlobResponse(blobFileData, extension) {
      const url = window.URL.createObjectURL(new Blob([blobFileData]));
      const link = document.createElement("a");
      const d = Date.now();
      link.href = url;
      link.setAttribute("download", "DocCardMapping_" + d + "." + extension); // nama file dan extension sesuaikan dengan file yang di download
      document.body.appendChild(link);
      link.click();
    },

    changeModal() {},

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateTime(val) {
      if (val) {
        let text = val;
        let result = text.includes("T");
        let jam = "";
        if (result == true) {
          // let text = "2022-04-05T09:45:32.719896";
          let val = text.split("T");
          let subst = val[1].substr(0, 8);
          jam = subst;
        } else {
          jam = "hh:mm";
        }
        return moment(val).format("YYYY-MM-DD " + jam);
      } else {
        return "-";
      }
    },
    dateOnly(val) {
      if (val) {
        return moment(val).format("LL");
      } else {
        return "-";
      }
    },

    getImageSiswa(val1, val2) {
      console.log(val2);
      // if(val2 === "" || val2 == null){
      //   return this.imageFotoHuman
      // }else{
      //   return "data:image/jpg;base64,"+val1;
      // }
      return "data:image/jpg;base64," + val1;
    },
    getStatusCetak(val) {
      if (val == true) {
        return "Sudah Dicetak";
      } else {
        return "Belum Dicetak";
      }
    },
    getStatusVerifikasiSekolah(val) {
      if (val == true) {
        return "Sudah Verifikasi";
      } else {
        return "Belum Verifikasi";
      }
    },
    getStatusVerifikasiAdmin(val) {
      if (val == true) {
        return "Sudah Verifikasi";
      } else {
        return "Belum Verifikasi";
      }
    },

    colorStatus(val) {
      if (val == true) {
        return "green";
      } else {
        return "red";
      }
    },

    convertTags(val) {
      console.log(val.length);
      const array = val;
      let text = "";
      if (val.length > 0) {
        for (let i = 0; i < val.length; i++) {
          if (i == 0) {
            text += val + "";
          } else {
            text != val[i] + ",";
          }
        }
      }
      console.log(text);
      return text;
    },
  },
});
